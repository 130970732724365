import css from './nav.module.css'
import { NavLink, Outlet } from 'react-router-dom'
import { useCurrentUser } from '../current-user'

export function LandingPage() {
    const currentUser = useCurrentUser()

    return (
        <>
            <nav className={css.nav + ' ' + css.main}>
                <NavLink to="">Landing page</NavLink>
                <NavLink to="info">Info</NavLink>
                <NavLink to="dev">Step into your app</NavLink>
            </nav>
            <div>
                <button
                    type="button"
                    onClick={currentUser.onRequestLogin}
                    disabled={currentUser.isLoading || currentUser.isAuthenticated}
                >
                    Login
                </button>
                <button
                    type="button"
                    onClick={currentUser.onLogout}
                    disabled={currentUser.isLoading || !currentUser.isAuthenticated}
                >
                    Logout
                </button>
                <span>{currentUser.name}</span>
            </div>

            <Outlet />
        </>
    )
}
