import { type PropsWithChildren, useCallback, useEffect, useId, useRef, useState } from 'react'
import { UserProvider } from '../current-user'
import { AppBar } from '../app-bar'
import { Content } from '../content'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { type MockContext, MockProvider } from './mock-provider'

const ANONYMOUS = 'Anonymous'
function useSyncedRef<T>(value: T) {
    const ref = useRef<T>(value)
    useEffect(() => {
        ref.current = value
    })

    return ref
}

function DevelopmentAuthWrapper({ children }: PropsWithChildren) {
    const [isLoading, setIsLoading] = useState(false)
    const [userName, setUserName] = useState<string>(() => sessionStorage.getItem('dev:username') ?? ANONYMOUS)
    const dialogRef = useRef<HTMLDialogElement>(null)
    const id = useId()

    useEffect(() => {
        if (userName === ANONYMOUS) {
            sessionStorage.removeItem('dev:username')
        } else {
            sessionStorage.setItem('dev:username', userName)
        }
    }, [userName])

    const userStatus = useSyncedRef({
        isLoading,
        userName,
        isAnonymous: userName === ANONYMOUS,
    })

    const onRequestLogin = useCallback(() => {
        setIsLoading(true)
        dialogRef.current?.showModal()
    }, [])

    const getAccessToken = useCallback(async () => {
        if (userStatus.current.isAnonymous) {
            return undefined
        }

        await new Promise((resolve) => setTimeout(resolve, 300))
        return {
            accessToken: 'base-app-mock-token',
        }
    }, [userStatus])

    const onLogout = useCallback(() => {
        setUserName(ANONYMOUS)
    }, [])

    return (
        <UserProvider
            appConfig={{
                API_BASE_PROXY_SERVICE: 'https://proxy.example.com/',
                API_BASE_TOKEN_SERVICE: 'https://base-app.example.com/',
                // API_BASE_WEBAUTHN: 'https://authn.example.com/',
                // AUTH_AUTHORITY: '#authority',
                // AUTH_CLIENT_ID: '#clientId',
                // AUTH_SCOPE: '#scope',
                // AUTH_REDIRECT_URL: '#redirect',
                // installationId: '#installation',
                // plugins: [],
                // VAPID_PUBLIC_KEY: '#publicKey',
            }}
            name={userName}
            id="00000000-0000-0000-0000-000000000000"
            isAuthenticated={userName !== ANONYMOUS}
            isLoading={isLoading}
            getAccessToken={getAccessToken}
            onRequestLogin={onRequestLogin}
            onLogout={onLogout}
        >
            {children}
            <dialog
                ref={dialogRef}
                onClose={() => setIsLoading(false)}
            >
                <h1>Login</h1>
                <form
                    method="dialog"
                    onSubmit={(event) => {
                        const data = new FormData(event.currentTarget)
                        const name = data.get('name')
                        if (typeof name === 'string') {
                            setUserName(name)
                        }
                        event.currentTarget.reset()
                    }}
                >
                    <fieldset>
                        <legend>Name</legend>
                        {[ANONYMOUS, 'Wouter', 'Jesper', 'Valerie', 'Henrik'].map((name) => {
                            return (
                                <label
                                    key={name}
                                    htmlFor={`${id}_name_${userName}`}
                                    id={`${id}_name_${userName}_label`}
                                >
                                    <input
                                        type="radio"
                                        name="name"
                                        id={`${id}_name_${userName}`}
                                        aria-labelledby={`${id}_name_${userName}_label`}
                                        defaultChecked={name === userName}
                                        value={name}
                                    />{' '}
                                    <span>{name}</span>
                                </label>
                            )
                        })}
                        <button type="submit">Login</button>
                    </fieldset>
                </form>
            </dialog>
        </UserProvider>
    )
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            throwOnError: true,
        },
    },
})

export function DevelopmentWrapper({ children, mocks }: PropsWithChildren<{ mocks: MockContext }>) {
    return (
        <MockProvider mocks={mocks}>
            <QueryClientProvider client={queryClient}>
                <DevelopmentAuthWrapper>
                    <AppBar />
                    <Content>{children}</Content>
                </DevelopmentAuthWrapper>
            </QueryClientProvider>
        </MockProvider>
    )
}
