import type { RegistrationConfiguration } from '../hooks'
import { Fragment } from 'react'

export function RegistrationInfo(props: { configuration: RegistrationConfiguration }) {
    const {
        configuration: { name, hooks },
    } = props

    const groupedHooks = Object.groupBy(hooks, (item) => item.type)

    return (
        <>
            <h1>Registration info</h1>
            <h2>Name</h2>
            {name}
            <h2>Hooks</h2>
            {Object.entries(groupedHooks).map(([groupName, hooksInGroup]) => (
                <Fragment key={groupName}>
                    <h3>{groupName}</h3>
                    <ul>
                        {hooksInGroup.map((hook) => (
                            <li key={hook.id}>{hook.title}</li>
                        ))}
                    </ul>
                </Fragment>
            ))}
        </>
    )
}
