import '../global.css'
import { createRoot } from 'react-dom/client'
import { DevelopmentWrapper } from './development-wrapper'
import { createBrowserRouter, Link, Outlet, RouterProvider } from 'react-router-dom'
import { HookContextProvider, type RegistrationConfiguration } from '../hooks'
import { LandingPage } from './landing-page'
import { RegistrationInfo } from './registration-info'
import css from './nav.module.css'
import type { MockContext } from './mock-provider'

export function ModuleRoute(props: { routes: Array<{ path: string; title: string }> }) {
    const { routes } = props
    return (
        <>
            <nav className={css.nav + ' ' + css.secondary}>
                {routes.map((route) => (
                    <Link
                        key={route.path}
                        to={route.path}
                    >
                        {route.title}
                    </Link>
                ))}
            </nav>
            <Outlet />
        </>
    )
}

export async function setupDevelopmentMode(
    document: Document,
    registrationConfig: RegistrationConfiguration,
    mocks: MockContext,
) {
    if (document.body.innerHTML !== '') {
        throw new Error('The body should be empty to successfully run development mode')
    }

    document.body.innerHTML = '<div id="root"></div>'
    const rootElement = document.getElementById('root')
    if (!rootElement) {
        throw new Error('Root element not found - this is strange, we just created it')
    }

    const routeChildren = registrationConfig.hooks
        .filter((hook) => hook.type === 'menu')
        .map((menuHook, index) => ({
            ...menuHook,
            path: `page-${index}`,
            element: <HookContextProvider {...menuHook} />,
        }))

    const router = createBrowserRouter([
        {
            path: '/',
            element: <LandingPage />,
            children: [
                {
                    element: <p>Here?</p>,
                    index: true,
                },
                {
                    element: <RegistrationInfo configuration={registrationConfig} />,
                    path: 'info',
                },
                {
                    path: '/dev/',
                    element: <ModuleRoute routes={routeChildren} />,
                    children: routeChildren,
                },
            ],
        },
    ])

    const reactRoot = createRoot(rootElement)
    reactRoot.render(
        <DevelopmentWrapper mocks={mocks}>
            <RouterProvider router={router} />
        </DevelopmentWrapper>,
    )
}
